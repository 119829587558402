export default {
    init: function () {
        // not entirely sure if this is still necessary
        window.XH_XmlHttpPOST = function (xmlHttp, url, data, handler) {
            xmlHttp.open("POST", url, true);
            xmlHttp.onreadystatechange = handler;
            xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            //XH_XmlHttpSend(xmlHttp, data);
        };
    },
};
