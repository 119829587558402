import curated from "./curated.js";
import narrowed from "./narrowed.js";
import keymatch from "./keymatch.js";
import _xhr from "./xhr.js";
import view from "./view.js";
//import $ from 'jquery';

// Overrides windows XHR for some cross domain issue fixing
_xhr.init();

// Init curated before document ready
curated.init();

// Init narrowed before document ready
narrowed.init();

// Init keymatch before document ready
keymatch.init();

document.addEventListener("DOMContentLoaded", () => {
    view.init();
});
