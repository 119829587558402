import utils from "./utils.js";
import $ from "jquery";

export default {
    getNarrowedResultsPath: function (_qstring) {
        return $("#narrow-search").data("ajaxpath") + "?q=" + _qstring;
    },
    init: function () {
        var _this = this;
        var searchTerm = utils.getParameterByName("q"); // find the current search term which is already in the dom
        if (searchTerm) {
            _this
                .searchByKeywordPromise(_this.getNarrowedResultsPath(searchTerm), searchTerm)
                .done(function (guy) {
                    console.log("Narrowed Search loaded, injecting narrowed results");

                    // concat the list elements into one chunk
                    var htmlListElements = $.map(guy, function (val) {
                        return "<li><a href='" + val.url + "&filter-track=narrowed'>" + val.label + "</a></li>";
                    }).join(" ");

                    return _this.injectNarrowedText(htmlListElements);
                })
                .fail(function (msg) {
                    return _this.showMessage("No narrowing is possible for this search query.");
                })
                .always(function () {
                    return _this.showNarrowedContentArea();
                });
        }
    },
    injectNarrowedText: function (_text) {
        // see 'narrowedSearch.html' within search component
        return $("#narrow-search").find(".narrow-list-wrapper").html(_text);
    },
    searchByKeywordPromise: function (_searchPath, _searchTerm) {
        var _promise = new $.Deferred();
        var parseResults = function (json) {
            if (json && json.hasResults && json.clusters && json.clusters.length > 0) {
                // now we map the array to something simpler
                var mappedClusters = $.map(json.clusters, function (val) {
                    return { label: val.labels[0], url: "?q=" + val.labels[0], score: val.score };
                });
                _promise.resolve(mappedClusters);
            } else {
                _promise.reject("No narrowing is possible for this search query.");
            }
        };
        $.getJSON(_searchPath).done(parseResults).fail(_promise.reject); // pipe the failure to curatedMatchPromise failure
        return _promise;
    },
    showMessage: function (text) {
        return $("#narrow-search").find(".title-text").html(text);
    },
    showNarrowedContentArea: function () {
        return $("#narrow-search").show();
    },
};
