import utils from "./utils.js";
import $ from "jquery";

export default {
    init: function () {
        var _this = this;

        var view = "grid";
        var viewParam = utils.getParameterByName("view");

        if (viewParam) {
            view = viewParam;
        }

        if (sessionStorage.getItem("resultView") == "grid") {
            _this.catalogView("grid");
        }

        $(".f-display__grid").on("click", function (event) {
            event.preventDefault();
            if (!$("#res").hasClass("results--grid")) {
                sessionStorage.setItem("resultView", "grid");
                _this.catalogView("grid");
            }
        });

        $(".f-display__list").on("click", function (event) {
            event.preventDefault();
            if (!$("#res").hasClass("results--list")) {
                sessionStorage.setItem("resultView", "list");
                _this.catalogView("list");
            }
        });
    },

    catalogView: function (_view) {
        if (_view == "grid") {
            $("#res").removeClass("results--list");
            $("#res").addClass("results--grid");

            $(".f-display__list").removeClass("on");
            $(".f-display__grid").addClass("on");
        }

        if (_view == "list") {
            $("#res").removeClass("results--grid");
            $("#res").addClass("results--list");

            $(".f-display__grid").removeClass("on");
            $(".f-display__list").addClass("on");
        }
    },
};
