import utils from "./utils.js";
import $ from "jquery";

// js logic from @Alan Young ImagineX
function pullMatchesFromItem(query, item) {
    var _query = query.toLowerCase().trim();
    var _queryArray = _query.split(" ").map(function (item) {
        return item.toLowerCase();
    });

    return item.keyMatches.filter(function (match) {
        var _matchArray = match
            .trim()
            .split(" ")
            .map(function (matchItem) {
                return matchItem.toLowerCase();
            });

        // if it's an exact match - we want to observe that immediately
        if (match.toLowerCase() === _query) {
            return true;
        }

        // basically if all of the query tokens exist 1:1 in the keymatch tokens then we're good
        var intersection = _matchArray.filter(function (item) {
            return _queryArray.includes(item);
        });
        return intersection.length === _matchArray.length;
    });
}

function processAllMatches(query, matches) {
    var _matches = matches.filter(function (keymatch) {
        var result = pullMatchesFromItem(query, keymatch);
        return result.length > 0;
    });
    // TODO refactor this to be configurable
    //_matches.length = 3;
    return _matches;
}

export default {
    filterItems: function (searchTerm, items) {
        return items;
    },
    getKeymatchSearchPath: function (_qstring) {
        return $("#keymatch").data("ajaxpath") + "?q=" + _qstring + "&noCache=1";
    },
    init: function () {
        var _this = this;
        var searchTerm = utils.getParameterByName("q"); // find the current search term which is already in the dom
        if (searchTerm) {
            _this
                .searchByKeywordPromise(_this.getKeymatchSearchPath(searchTerm), searchTerm)
                .done(function (guy) {
                    console.log("Keymatch Search loaded (v1.1), injecting narrowed results");

                    // concat the list elements into one chunk
                    var keymatchElements = $.map(guy, function (val) {
                        return "<div class='res-item'><a href='" + val.url + "' class='rtitle'>" + val.title + "</a><span class='rlink'>" + val.url + "</span></div>";
                    }).join(" ");

                    // wait for doc ready before attempting to inject
                    $(document).ready(function () {
                        _this.injectKeymatchText(keymatchElements);
                        return _this.showKeymatchContentArea();
                    });
                })
                .fail(function (msg) {
                    console.log(msg);
                });
        }
    },
    injectKeymatchText: function (_text) {
        // see 'narrowedSearch.html' within search component
        return $("#keymatch").find(".keymatch-content-wrapper").html(_text);
    },
    searchByKeywordPromise: function (_searchPath, _searchTerm) {
        var _promise = new $.Deferred();
        var parseResults = function (json) {
            if (json && json.hasResults && json.items && json.items.length > 0) {
                var processedItems = processAllMatches(_searchTerm, json.items);
                if (processedItems.length > 0) {
                    _promise.resolve(processedItems);
                } else {
                    _promise.reject("Keymatch Search - no exact matches for '" + _searchTerm + "'");
                }
            } else {
                _promise.reject("Keymatch Search - no matches for '" + _searchTerm + "'");
            }
        };
        $.getJSON(_searchPath).done(parseResults).fail(_promise.reject); // pipe the failure to curatedMatchPromise failure
        return _promise;
    },
    showKeymatchContentArea: function () {
        return $("#keymatch").show();
    },
};
