import utils from "./utils.js";
import $ from "jquery";

export default {
    getCuratedSearchPath: function () {
        var _ajaxPathFromDOM = $("#curated").data("ajaxpath");
        return _ajaxPathFromDOM ? _ajaxPathFromDOM : "/etc/designs/acs/curated/acs/_jcr_content.json?cachebuster";
    },
    init: function () {
        var _this = this;
        var _old_XH_XmlHttpPOST = XH_XmlHttpPOST;

        // not entirely sure if this is still necessary
        window.XH_XmlHttpPOST = function (xmlHttp, url, data, handler) {
            xmlHttp.open("POST", url, true);
            xmlHttp.onreadystatechange = handler;
            xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            XH_XmlHttpSend(xmlHttp, data);
        };
        var searchTerm = utils.getParameterByName("q"); // find the current search term which is already in the dom
        if (searchTerm) {
            searchTerm = searchTerm.toUpperCase();
            _this
                .searchByKeywordPromise(_this.getCuratedSearchPath(), searchTerm)
                .done(function (guy) {
                    console.log("Curated Search loaded, injecting content snippet from curated result at path " + guy.path);
                    // wait for doc ready before attempting to inject
                    $(document).ready(function () {
                        var newText = "<div>" + guy.snippet + "</div>";
                        _this.injectCuratedText(newText);
                        return _this.showCuratedContentArea();
                    });
                })
                .fail(function (msg) {
                    console.log(msg);
                });
        }
    },
    injectCuratedText: function (_text) {
        // see 'curated.html' within search component

        return $("#curated").find(".inner-content").html(_text);
    },
    searchByKeywordPromise: function (_searchPath, _searchTerm) {
        var _promise = new $.Deferred();
        var parseResults = function (json) {
            var matchedResult = null;
            for (var i = 0; i < json.results.length; i++) {
                var pg = json.results[i];
                if (pg && pg.curatedTag && pg.curatedTag.toUpperCase() === _searchTerm) {
                    matchedResult = pg;
                    break;
                }
            }
            if (matchedResult && matchedResult.hasResultsSnippet) {
                console.log(matchedResult);
                _promise.resolve(matchedResult);
            } else {
                _promise.reject("Curated Search - match not found for given search term '" + _searchTerm + "'");
            }
        };
        $.getJSON(_searchPath)
            .done(function (json) {
                if (amplify && amplify.store) {
                    amplify.store("curatedResultSet", json, { expires: 86400000 }); //one day expiration
                }
                parseResults(json);
            })
            .fail(_promise.reject); // pipe the failure to curatedMatchPromise failure
        return _promise;
    },
    showCuratedContentArea: function () {
        return $("#curated").show();
    },
};
